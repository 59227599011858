.App {
  text-align: center;
  background-color: darkgray;
  height: 100%;
}

.App header {
  height: 100px;
  background-color: gray;
  color: wheat;
}

li {
  list-style-type: none;
}